/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    userList: [], 
    companyList:[],
    tenantList:[],
    customerList:[], 
    role:[],
    destType:[],
    clidType:[],
    location:[],
    locationsingle:[],
    strategyType:[],
    customerSingle:[],
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
        case "ROLE_LIST": {
            return {
              ...state,
              role: action.payload,
            };
          }
      case "USER_LIST": {
        return {
          ...state,
          userList: action.payload,
        };
      } 
      case "COMPANY_LIST": {
        return {
          ...state,
          companyList: action.payload,
        };
      } 
      case "TENANT_LIST": {
        return {
          ...state,
          tenantList: action.payload,
        };
      } 
      case "CUSTOMER_LIST": {
        return {
          ...state,
          customerList: action.payload,
        };
      } 
      case "CUSTOMER_SINGLE": {
        return {
          ...state,
          customerSingle: action.payload,
        };
      } 
      case "DELETE_COMPANY": {
        return {
          ...state,
          companyList: [
            ...state.companyList.filter((x) => x.id !== action.payload),
          ],
        };
      }
      case "DELETE_TENANT": {
        return {
          ...state,
          tenantList: [
            ...state.tenantList.filter((x) => x.id !== action.payload),
          ],
        };
      }
      case "DELETE_ENDUSER": {
        return {
          ...state,
          customerList: [
            ...state.customerList.filter((x) => x.id !== action.payload),
          ],
        };
      }
      case "DEST_TYPE": {
        return {
          ...state,
          destType: action.payload,
        };
      }
      case "CLID_TYPE": {
        return {
          ...state,
          clidType: action.payload,
        };
      } 

      case "STRATEGY": {
        return {
          ...state,
          strategyType: action.payload,
        };
      } 
      case "LOCATION": {
        return {
          ...state,
          location: action.payload,
        };
      }
      case "LOCATION_SINGLE": {
        return {
          ...state,
          locationsingle: action.payload,
        };
      }
  
      case "DELETE_LOCATION": {
        return {
          ...state,
          location: [
            ...state.location.filter((x) => x.id !== action.payload),
          ],
        };
      }
      default: {
        return state;
      }
    }
  };
  