
import darkCompact from "./dark-compact.theme.less";
import lightCompact from "./light-compact.theme.less";
import { useEffect } from "react";
import { keyBy } from "lodash-es";
import { useDispatch } from "react-redux";

export const themes = [
  // {
  //   id: "light", // used as value in the select
  //   displayName: "Light", // used as label in the select
  //   styles: light, // the style module
  // },
  {
    id: "lightCompact",
    displayName: "Light",
    styles: lightCompact,
  },
  // {
  //   id: "dark",
  //   displayName: "Dark",
  //   styles: dark,
  // },
  {
    id: "darkCompact",
    displayName: "Dark",
    styles: darkCompact,
  },
];

const themeMap = keyBy(themes, "id");
export function useAntdTheme(themeId) {
  const dispatch = useDispatch();
  // themeId is the active (i. e. selected) theme
  
  useEffect(() => {
    localStorage.setItem("selectedtheme", themeId);
    dispatch({type:"SELECTED_THEME", payload:themeId});
    const { styles } = themeMap[themeId]; // 2. retrieve the imported style module for current themeId
    styles?.use(); // 3. apply the styling
    return () => styles?.unuse(); // 1. unapply the previous styling (if any)
  }, [themeId]);
}

export const defaultThemeId = localStorage.getItem("selectedtheme")?localStorage.getItem("selectedtheme"):themes[0].id;