/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    teamlists:[],
    teamlistsort: [],
    teamsingle:[],
    teamnotuser:[],
    teaminuser:[],    
    };
    
    export default (state = initialState, action) => {
      switch (action.type) {
        //Team reducer
        case "TEAMS":{
          return {
            ...state,
            teamlists: action.payload,
          };
        }
        case "TEAMS_LIST": {
          return {
            ...state,
            teamlistsort: action.payload,
          };
        }
        case "TEAMS_SINGLE": {
          return {
            ...state,
            teamsingle: action.payload,
          };
        }
        case "DELETE_TEAMS": {
          return {
            ...state,
            teamlistsort: [
              ...state.teamlistsort.results?.filter((x) => x.id !== action.payload),
            ],
          };
        }
        case "TEAM_NOT_USER": {
          return {
            ...state,
            teamnotuser: action.payload,
          };
        }
        case "TEAM_IN_USER": {
          return {
            ...state,
            teaminuser: action.payload,
          };
        }
         
        case "DELETE_TEAM_MAP": {
          return {
            ...state,
            teaminuser: [
              ...state.teaminuser.results?.filter((x) => x.id !== action.payload),
            ],
          };
        }
        default: {
          return state;
        }
      }
    };