/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    groupblacklist:[],
    groupblacklists:[],
    groupblacklistsingle:[],
    blacklist:[],
    blacklists:[],
    blacklistsingle:[],
    didnumber:[],
    phonenumber:[],
    phonenumbers:[],
    phonenumbersingle:[],  
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case "GROUP_BLACKLIST": {
        return {
          ...state,
          groupblacklist: action.payload,
        };
      }
    
      case "GROUP_BLACKLISTS": {
        return {
        ...state,
        groupblacklists: action.payload,
      };
      }
      case "GROUP_BLACKLIST_SINGLE": {
        return { 
          ...state, 
          groupblacklistsingle: action.payload };
      }
      case "DELETE_BLACKLIST_GROUP": {
        return {
          ...state,
          groupblacklists: [
            ...state.groupblacklists.results?.filter((x) => x.id !== action.payload),
          ],
        };
      }
      
      case "BLACKLIST": {
        return {
          ...state,
         blacklist: action.payload,
        };
      }
    
      case "BLACKLISTS": {
        return {
        ...state,
        blacklists: action.payload,
      };
      }
      case "BLACKLIST_SINGLE": {
        return { 
          ...state, 
          singleblacklist: action.payload };
      }
      case "DELETE_BLACKLIST": {
        return {
          ...state,
         blacklists: [
            ...state.blacklists.results?.filter((x) => x.id !== action.payload),
          ],
        };
      }

      case "PHONENUMBER": {
        return {
          ...state,
          phonenumber: action.payload,
        };
      }
      case "DIDS": {
        return {
          ...state,
          didnumber: action.payload,
        };
      }
      case "PHONENUMBERS": {
        return {
        ...state,
        phonenumbers: action.payload,
      };
      }
      case "PHONENUMBER_SINGLE": {
        return { 
          ...state, 
          phonenumbersingle: action.payload };
      }
      case "DELETE_PHONENUMBER": {
        return {
          ...state,
          phonenumbers: [
            ...state.phonenumbers.results?.filter((x) => x.id !== action.payload),
          ],
        };
      }
    
      default: {
        return state;
      }
    }
  };
  