 
const initialState = {
    data: null,
    error: null,
    setting:[],
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
        case "PING":
          return {
            ...state,
            data: action.payload,
            error: null,
          };
        case "PING_FAILURE":
          return {
            ...state,
            data: null,
            error: action.payload, // Assuming the error is included in the action payload
          };
          case "COMPANY_SETTING":
            return { ...state, setting: action.payload };
        default:
          return state;
      }
    };
 