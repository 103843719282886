/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  audiolists: [],
  audiolistsort: [],
  singleaudio: {},
  audiogrouplists: [],
  audiogrouplistsort: [],
  singleaudiogroup: {},
  musiclists: [],
  musiclistsort: [],
  singlemusic: {},
  voicemails: [],
  voicemailsort: [],
  singlevoicemail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    //Audio File
    case "AUDIO_LISTS": {
      return {
        ...state,
        audiolists: action.payload,
      };
    }
    case "AUDIO_LIST_SORT": {
      return {
        ...state,
        audiolistsort: action.payload,
      };
    }
    case "AUDIO_SINGLE": {
      return {
        ...state,
        singleaudio: action.payload,
      };
    }

    case "DELETE_AUDIO": {
      return {
        ...state,
        audiolistsort: [
          ...state.audiolistsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    //AUDIO GROUP REDUCER
    case "AUDIO_GROUP_LISTS": {
      return {
        ...state,
        audiogrouplists: action.payload,
      };
    }
    case "AUDIO_GROUP_LIST_SORT": {
      return {
        ...state,
        audiogrouplistsort: action.payload,
      };
    }
    case "AUDIO_GROUP_SINGLE": {
      return {
        ...state,
        singleaudiogroup: action.payload,
      };
    }

    case "DELETE_AUDIO_GROUP": {
      return {
        ...state,
        audiogrouplistsort: [
          ...state.audiogrouplistsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }

    //MUSIC ON HOLD REDUCER
    case "MUSIC_LISTS": {
      return {
        ...state,
        musiclists: action.payload,
      };
    }
    case "MUSIC_LIST_SORT": {
      return {
        ...state,
        musiclistsort: action.payload,
      };
    }
    case "MUSIC_SINGLE": {
      return {
        ...state,
        singlemusic: action.payload,
      };
    }
    case "DELETE_MUSIC": {
      return {
        ...state,
        musiclistsort: [
          ...state.musiclistsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "VOICEMAIL_LISTS": {
      return {
        ...state,
        voicemails: action.payload,
      };
    }
    case "VOICEMAIL_LIST_SORT": {
      return {
        ...state,
        voicemailsort: action.payload,
      };
    }
    case "VOICEMAIL_SINGLE": {
      return {
        ...state,
        singlevoicemail: action.payload,
      };
    }
    case "DELETE_VOICEMAIL": {
      return {
        ...state,
        voicemailsort: [
          ...state.voicemailsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
