/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  contactcenter: [],
  contactcentersort: [],
  singlecontactcenter: [],
  contactcenteragents: [],
  contactcenteragentsort: [],
  singlecontactcenteragents: [],
  agentmapper: [],
  agentmappersort: [],
  agentincontact: [],
  agentnotincontact: [],
  useragents:[],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONTACT_CENTER": {
      return {
        ...state,
        contactcenter: action.payload,
      };
    }
    case "CONTACT_CENTER_SORT": {
      return {
        ...state,
        contactcentersort: action.payload,
      };
    }
    case "CONTACT_CENTER_SINGLE": {
      return {
        ...state,
        singlecontactcenter: action.payload,
      };
    }

    case "DELETE_CONTACT_CENTER": {
      return {
        ...state,
        contactcentersort: [
          ...state.contactcentersort?.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "USER_AGENT": {
      return {
        ...state,
        useragents: action.payload,
      };
    }
    case "CONTACT_CENTER_AGENT": {
      return {
        ...state,
        contactcenteragents: action.payload,
      };
    }
    case "CONTACT_CENTER_AGENT_SORT": {
      return {
        ...state,
        contactcenteragentsort: action.payload,
      };
    }
    case "CONTACT_CENTER_AGENT_SINGLE": {
      return {
        ...state,
        singlecontactcenteragents: action.payload,
      };
    }
    case "DELETE_CONTACT_CENTER_AGENT": {
      return {
        ...state,
        contactcenteragentsort: [
          ...state.contactcenteragentsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    //reducer for AGENT mapper

    case "AGENT_MAPPER": {
      return {
        ...state,
        agentmapper: action.payload,
      };
    }
    case "AGENT_MAPPER_SORT": {
      return {
        ...state,
        agentmappersort: action.payload,
      };
    }

    case "AGENT_IN_CONTACT": {
      return {
        ...state,
        agentincontact: action.payload,
      };
    }
    case "AGENT_NOT_CONTACT": {
      return {
        ...state,
        agentnotincontact: action.payload,
      };
    }
    case "DELETE_AGENT_MAPPER": {
      return {
        ...state,
        agentincontact: [
          ...state.agentincontact?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    //announcement reducer
    case "DELETE_ANNOUNCEMENT": {
      // Filtering out the deleted announcement
      const updatedAnnouncements = state.singlecontactcenter?.filter(
        (announcement) =>
          announcement.id !== action.payload.id &&
          announcement.key !== action.payload.key
      );
      return {
        ...state,
        singlecontactcenter: updatedAnnouncements,
      };
    }
    default: {
      return state;
    }
  }
};
