
import moment from "moment-timezone";
const url = new URL(window.location.href);
var pathSegments = url.pathname.split("/");
var tenantId = pathSegments[2];
const key = `tenant_${tenantId}`;

const username = localStorage.getItem("username");
const selectedtenant = JSON.parse(localStorage.getItem(key) || "{}");

const INITIAL_STATE = {
  uiReport: {
    user: window.location.pathname.includes("/view-tenant/")
      ? [selectedtenant?.first_name]
      : [username],
    timezone: localStorage.getItem("timezones"),
    // timezone: window.location.pathname.includes("/view-tenant/")
    // ? [selectedtenant?.timezone]
    // : localStorage.getItem("timezones"),
    time: [moment().startOf("day"), moment().endOf("day")],
  },
  uiColumns: {
    contactSetting: [],
    userSetting: [],
    cfProSetting: [],
  },
  
  hidencolumns: [],
  callhidencolumns: [],
  cfprohidencolumns: [],

  activefilter: {},
  filter: [],
  callgrid: {},
  timeprops: [],
  timechart: [],
  columns: [],
  querycdr: [],
  count: 0,
  insightgroup: [],
  insightattribute: [],
  insight: [],

  // contactreducer
  calluiReport: {
    user: window.location.pathname.includes("/view-tenant/")
      ? [selectedtenant?.first_name]
      : [username],
    timezone: localStorage.getItem("timezones"),
    time: [moment().startOf("day"), moment().endOf("day")],
  },
  callactivefilter: {},
  callfilter: [],
  callgrids: {},
  callcolumns: [],
  calltimeprops: [],
  calltimechart: [],
  callquerycdr: [],
  callcount: 0,
  callinsightgroup: [],
  callinsightattribute: [],
  callinsight: [],

  // cfprosetting reducer
  cfprouiReport: {
    user: window.location.pathname.includes("/view-tenant/")
      ? [selectedtenant?.first_name]
      : [username],
    timezone: localStorage.getItem("timezones"),
    time: [moment().startOf("day"), moment().endOf("day")],
  },
  cfproactivefilter: {},
  cfprofilter: [],
  cfprogrids: {},
  cfprocolumns: [],
  cfprotimeprops: [],
  cfprotimechart: [],
  cfproquerycdr: [],
  cfprocount: 0,
  cfproinsightgroup: [],
  cfproinsightattribute: [],
  cfproinsight: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "REPORT_USER": {
      return {
        ...state,
        uiReport: { ...state.uiReport, user: action.payload },
      };
    }
    case "REPORT_TIMEZONE": {
      // Calculate the time range based on the selected timezone
      const selectedTimezone = action.payload;
      const currentTime = moment().tz(selectedTimezone);
      const startOfToday = currentTime.clone().startOf("day");
      const endOfToday = currentTime.clone().endOf("day");

      // Update the state with the new time range
      return {
        ...state,
        uiReport: {
          ...state.uiReport,
          timezone: selectedTimezone,
          time: [startOfToday, endOfToday],
        },
      };
    }
    case "REPORT_TIME": {
      return {
        ...state,
        uiReport: { ...state.uiReport, time: action.payload },
      };
    }

    case "ORG_FILTERS": {
      return { ...state, activefilter: action.payload };
    }
    case "FILTERS": {
      return { ...state, filter: action.payload };
    }
    case "UPDATE_COLUMNS_PROP": {
      return {
        ...state,
        columns: action.payload,
      };
    }
    case "HIDDEN_COLUMNS": {
      return { ...state, hidencolumns: action.payload };
    }

    case "CALL_GRID": {
      return {
        ...state,
        callgrid: action.payload,
      };
    }

    case "GET_UISETTING": {   
      return {
        ...state,
        uiColumns: {
          contactSetting: action.payload?.contactSetting || [],
          userSetting: action.payload?.userSetting || [],
          cfProSetting: action.payload?.cfProSetting || [],
        },
        hidencolumns: action.payload?.userSetting ? action.payload?.userSetting.map((x) => x.id) : [4],
        callhidencolumns: action.payload?.contactSetting ? action.payload?.contactSetting.map((x) => x.id) : [40],
        cfprohidencolumns: action.payload?.cfProSetting ? action.payload?.cfProSetting.map((x) => x.id) : [63],
      };
    }

    case "COLUMNS_PROP": {
      return {
        ...state,
        columns: [...action.payload].map((x) => ({
          ...x,
          show: state.hidencolumns.includes(x.id),
        })),
      };
    }
    case "CDR_DATA_QUERY": {
      return {
        ...state,
        querycdr: action?.payload[0],
        count: action?.payload[1],
      };
    }

    case "TIME_PROP": {
      return {
        ...state,
        timeprops: action.payload,
      };
    }
    case "CDR_TIMELINE": {
      return {
        ...state,
        timechart: action.payload,
      };
    }
    case "INSIGHT_GROUP": {
      return {
        ...state,
        insightgroup: action.payload,
      };
    }
    case "INSIGHT_ATTRIBUTE": {
      return {
        ...state,
        insightattribute: action.payload,
      };
    }
    case "CDR_INSIGHT": {
      return {
        ...state,
        insight: action.payload,
      };
    }

// contact report ReducerType
    case "REPORT_CALL_USER": {
      return {
        ...state,
        calluiReport: { ...state.calluiReport, user: action.payload },
      };
    }
    case "REPORT_CALL_TIMEZONE": {
      // Calculate the time range based on the selected timezone
      const selectedTimezone = action.payload;
      const currentTime = moment().tz(selectedTimezone);
      const startOfToday = currentTime.clone().startOf("day");
      const endOfToday = currentTime.clone().endOf("day");

      // Update the state with the new time range
      return {
        ...state,
        calluiReport: {
          ...state.calluiReport,
          timezone: selectedTimezone,
          time: [startOfToday, endOfToday],
        },
      };
    }
    case "REPORT_CALL_TIME": {
      return {
        ...state,
        calluiReport: { ...state.calluiReport, time: action.payload },
      };
    }

    case "ORG_CALL_FILTERS": {
      return { ...state, callactivefilter: action.payload };
    }
    case "CALL_FILTERS": {
      return { ...state, callfilter: action.payload };
    }
    case "UPDATE_CALL_COLUMNS_PROP": {
      return {
        ...state,
        callcolumns: action.payload,
      };
    }
    case "HIDDEN_CALL_COLUMNS": {
      return { ...state, callhidencolumns: action.payload };
    }
    case "CALL_GRIDS": {
      return {
        ...state,
        callgrids: action.payload,
      };
    }

    case "CALL_COLUMNS_PROP": {
      return {
        ...state,
        callcolumns: [...action.payload].map((x) => ({
          ...x,
          show: state.callhidencolumns.includes(x.id),
        })),
      };
    }
    case "CALL_CDR_DATA_QUERY": {
      return {
        ...state,
        callquerycdr: action?.payload[0],
        callcount: action?.payload[1],
      };
    }
    case "CALL_TIME_PROP": {
      return {
        ...state,
        calltimeprops: action.payload,
      };
    }
    case "CALL_CDR_TIMELINE": {
      return {
        ...state,
        calltimechart: action.payload,
      };
    }
    case "CALL_INSIGHT_GROUP": {
      return {
        ...state,
        callinsightgroup: action.payload,
      };
    }
    case "CALL_INSIGHT_ATTRIBUTE": {
      return {
        ...state,
        callinsightattribute: action.payload,
      };
    }
    case "CALL_CDR_INSIGHT": {
      return {
        ...state,
        callinsight: action.payload,
      };
    }

// callpro reducer

    case "REPORT_CALLPRO_USER": {
      return {
        ...state,
        cfprouiReport: { ...state.cfprouiReport, user: action.payload },
      };
    }
    case "REPORT_CALLPRO_TIMEZONE": {
      // Calculate the time range based on the selected timezone
      const selectedTimezone = action.payload;
      const currentTime = moment().tz(selectedTimezone);
      const startOfToday = currentTime.clone().startOf("day");
      const endOfToday = currentTime.clone().endOf("day");

      // Update the state with the new time range
      return {
        ...state,
        cfprouiReport: {
          ...state.cfprouiReport,
          timezone: selectedTimezone,
          time: [startOfToday, endOfToday],
        },
      };
    }
    case "REPORT_CALLPRO_TIME": {
      return {
        ...state,
        cfprouiReport: { ...state.cfprouiReport, time: action.payload },
      };
    }

    case "ORG_CALLPRO_FILTERS": {
      return { ...state, cfproactivefilter: action.payload };
    }
    case "CALLPRO_FILTERS": {
      return { ...state, cfprofilter: action.payload };
    }

    case "UPDATE_CALLPRO_COLUMNS_PROP": {
      return {
        ...state,
        cfprocolumns: action.payload,
      };
    }
    case "HIDDEN_CALLPRO_COLUMNS": {
      return { ...state, cfprohidencolumns: action.payload };
    }
    case "CALLPRO_GRIDS": {
      return {
        ...state,
        cfprogrids: action.payload,
      };
    }
    case "CALLPRO_COLUMNS_PROP": {
      return {
        ...state,
        cfprocolumns: [...action.payload].map((x) => ({
          ...x,
          show: state.cfprohidencolumns.includes(x.id),
        })),
      };
    }
    case "CALLPRO_CDR_DATA_QUERY": {
      return {
        ...state,
        cfproquerycdr: action?.payload[0],
        cfprocount: action?.payload[1],
      };
    }
    case "CALLPRO_TIME_PROP": {
      return {
        ...state,
        cfprotimeprops: action.payload,
      };
    }
    case "CALLPRO_CDR_TIMELINE": {
      return {
        ...state,
        cfprotimechart: action.payload,
      };
    }
    case "CALLPRO_INSIGHT_GROUP": {
      return {
        ...state,
        cfproinsightgroup: action.payload,
      };
    }
    case "CALLPRO_INSIGHT_ATTRIBUTE": {
      return {
        ...state,
        cfproinsightattribute: action.payload,
      };
    }
    case "CALLPRO_CDR_INSIGHT": {
      return {
        ...state,
        cfproinsight: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
