import auth from "./auth";
import loader from "./loader";
import { combineReducers } from "redux";
import contact from "./contact";
import callergroup from "./callerid";
import ping from "./ping";
import user from "./users";
import phoneNumber from "./phonenumber";
import audio from "./audio";
import contacts from "./contactCenter";
import callPro from "./callForwarding";
import sip from "./sipDevice";
import teams from "./team";
import userReport from "./userReports";
import cfProReport from "./callProReport";
import contactReport from "./contactReport";
import liveStatus from "./liveStatus";
// import modalReducer from "./feature/modal";
// import targetLinkReducer from "./feature/targetLinks";
// import gotoNodeSlice from "./feature/gotoNode";
// import deleteDialogSlice from "./feature/deleteDialog";

const appReducer = combineReducers({
  auth,
  ping,
  loader,
  contact,
  callergroup,
  user,
  phoneNumber,
  audio,
  sip,
  contacts,
  callPro,
  teams,
  userReport,
  // cfProReport,
  // contactReport,
  liveStatus,
  // deleteDialog: deleteDialogSlice,
  // modal: modalReducer,
  // targetLinks: targetLinkReducer,
  // gotoNode: gotoNodeSlice,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "USER_LOGGED_OUT") {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
