// useSocket.js
import React, { useEffect, useState } from "react";
import io from "socket.io-client";

const useDynamicSocket = process.env.REACT_APP_USE_DYNAMIC_SOCKET;
const apiUrl = process.env.REACT_APP_SOCKET_ENDPOINT;

const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const baseUrl = localStorage.getItem("BaseURL");
    const _SOCKET_URL =
      useDynamicSocket === "true" ? `${baseUrl}/` : `${apiUrl}`;
      console.log("Socket URL being used:", _SOCKET_URL);
    if (_SOCKET_URL) {
      const socketIo = io(_SOCKET_URL, {
        transports: ["websocket"],
        reconnect: true,
        query: `token=${localStorage.getItem("accessToken")}`,
      });

      socketIo.on("connect", () => {
        console.log("Connected to socket");
      });

      socketIo.on("disconnect", () => {
        console.log("Disconnected from socket");
        setTimeout(() => {
          socketIo.connect();
        }, 1000);
      });
      setSocket(socketIo);

      return () => {
        if (socketIo) {
          socketIo.disconnect();
        }
      };
    }
  }, []);

  return socket;
};

export default useSocket;
export const SocketContext = React.createContext();

// import Cookies from "js-cookie";
// import React from "react";
// import io from "socket.io-client";

// // Get environment variables and local storage values
// const baseUrl = localStorage.getItem("BaseURL");
// const useDynamicSocket = process.env.REACT_APP_SOCKET_ENDPOINT;
// const apiUrl = process.env.REACT_APP_SOCKET_ENDPOINT;

// // Decide socket URL based on condition
// export const _SOCKET_URL =
//   useDynamicSocket === "true" ? `${baseUrl}/` : `${apiUrl}`;

// console.log("Socket URL:", _SOCKET_URL);

// // Function to initialize the socket connection
// export const initializeSocket = () => {
//   const socket = io(_SOCKET_URL, {
//     transports: ["websocket"],
//     reconnect: true,
//     query: `token=${localStorage.getItem("accessToken")}`,
//   });

//   // Handle socket events
//   socket.open();

//   socket.on("connect", () => {
//     console.log("Socket connected");
//   });

//   socket.on("disconnect", () => {
//     console.log("Socket disconnected");
//     setTimeout(() => {
//       socket.connect();
//     }, 1000); // Reconnect after 1 second
//   });

//   return socket;
// };

// // Context to provide the socket throughout the app
// export const SocketContext = React.createContext();

// import Cookies from "js-cookie";
// import React from "react";
// import io from "socket.io-client";

// const baseUrl = localStorage.getItem("BaseURL");
// const useDynamicSocket = process.env.REACT_APP_SOCKET_ENDPOINT;
// const apiUrl = process.env.REACT_APP_SOCKET_ENDPOINT;
// // const usePort =
// //   process.env.REACT_APP_DYNAMIC_SOCKET_PORT !== ""
// //     ? `:${process.env.REACT_APP_DYNAMIC_SOCKET_PORT}/`
// //     : "";
// export const _SOCKET_URL =
//   useDynamicSocket === "true" ? `${baseUrl}/` : `${apiUrl}`;

// // const ENDPOINT = process._SOCKET_URL;
// console.log("socket", _SOCKET_URL);
// export const socket = io(_SOCKET_URL, {
//   //   path:"/websocket/socket.io",
//   transports: ["websocket"],
//   reconnect: true,
//   // reconnectionDelay: 1000,
//   query: `token=${localStorage.getItem("accessToken")}`,
// });

// socket.open();
// socket.on("connect", function () {
//   console.log("connect");
// });

// socket.on("disconnect", function () {
//   setTimeout(() => {
//     socket.connect();
//   }, 1000);
// });

// export const SocketContext = React.createContext();
