import React, { createContext, useContext, useState } from "react";

const CountContext = createContext();

export const CountProvider = ({ children }) => {
  const [totalDeviceCCCount, setTotalDeviceCCCount] = useState(0);
  const [totalDevice6Count, setTotalDevice6Count] = useState(0);
  const [totalDeviceUserCount, setTotalDeviceUserCount] = useState(0);
  const [waitingCount, setWaitingCount] = useState(0);

  return (
    <CountContext.Provider
      value={{
        totalDeviceCCCount,
        setTotalDeviceCCCount,
        totalDevice6Count,
        setTotalDevice6Count,
        totalDeviceUserCount,
        setTotalDeviceUserCount,
        waitingCount,
        setWaitingCount,
      }}
    >
      {children}
    </CountContext.Provider>
  );
};

export const useCount = () => useContext(CountContext);
