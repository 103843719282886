/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  contactlist:[],
  contactlistsort: [],
  singlecontact:{},
  contactBlob: {},
  grouplists: [],
  grouplistsort: [],
  single: [],
  groupncontact: [],
  contactgroupsearch: {},
  contactngroupsearch: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CONTACT": {
      return {
        ...state,
        contactlist: action.payload,
      };
    }
    case "CONTACT_LIST_ID": {
      return { ...state, singlecontact: action.payload };
    }
    case "CONTACT_LIST_SORT": {
      return {
        ...state,
        contactlistsort: action.payload,
      };
    }

    case "CONTACT_LIST_CSV": {
      return {
        ...state,
        contactBlob: action.payload,
      };
    }

    case "DELETE_CONTACT": {
      return {
        ...state,
        ccontactlistsort: [
          ...state.contactlistsort.results?.filter(
            (x) => x.id !== action.payload
          ),
        ],
      };
    }
    case "GROUP_LISTS":
      return {
        ...state,
        grouplists: action.payload,
      };
    case "GROUP_LIST_SORT": {
      return { ...state, grouplistsort: action.payload };
    }
    case "GROUP_SINGLE": {
      return { ...state, single: action.payload };
    }
    case "NGROUP_CONTACT": {
      return { ...state, groupncontact: action.payload };
    }
    case "GROUP_SINGLE_SEARCH": {
      return {
        ...state,
        contactgroupsearch: action.payload,
      };
    }
    case "NGROUP_CONTACT_SEARCH": {
      return {
        ...state,
        contactngroupsearch: action.payload,
      };
    }
    case "DELETE_GROUP": {
      return {
        ...state,
        grouplistsort: [
          ...state.grouplistsort.results?.filter((x) => x.id !== action.payload),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
