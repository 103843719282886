/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  loading: false,
  theme: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return { ...state, loading: true };
    case "HIDE_LOADER":
      return { ...state, loading: false };
    case "SELECTED_THEME":
      return { ...state, theme: action.payload };

    default:
      return state;
  }
};
